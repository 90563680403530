import React from "react";
import { useParams, Navigate, Link } from "react-router-dom";
import { models } from "../../../assets/json/data";
import { HeaderModel } from "../../../components/HeaderModel";
import { motion } from "framer-motion";

export const Path = () => {
  const { path } = useParams();
  const model = models.find((model) => model.path === path);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
      {!model ? (
        <Navigate to="/modelos" />
      ) : (
        <>
          <p className=" font-bold text-sm text-black  xl:mt-9 lg:mt-9 xl:ml-20 md:mt-5 sm:mt-3 xs:mt-3 lg:ml-20 md:ml-5 sm:ml-5 xs:ml-3">
            <Link to="/">INICIO</Link> /{" "}
            <Link to="/modelos"> NUESTROS MODELOS</Link>{" "}
            <span className=" text-gray-500">/ NOMBRE</span>
          </p>
          <div className="container mx-auto  px-0 xl:px-0 lg:px-4 sm:px-4 xs:px-1 md:px-4">
            <div className="mt-9 mb-12 text-center">
              <div className="xl:block lg:block md:block sm:hidden xs:hidden">
                <HeaderModel text={model.data.name} />
              </div>
              <p className="xl:hidden lg:hidden md:hidden sm:block xs:block text-black mt-3 lg:text-xl md:text-xl xl:text-xl font-bold leading-6 tracking-[.2em]">
                {model.data.name}
              </p>
              <Link
                onClick={(e) => {
                  window.location = `tel:+51${model.data.phone.replace(
                    /\s+/g,
                    ""
                  )}`;
                  e.preventDefault();
                }}
              >
                <p className=" text-black mt-3 lg:text-xl md:text-xl xl:text-xl font-bold leading-6 tracking-[.2em]">
                  {model.data.phone}
                </p>
              </Link>

              <Link
                to={"#"}
                onClick={(e) => {
                  window.location = `mailto:${model.data.email}`;
                  e.preventDefault();
                }}
              >
                <p className="text-black mt-3 lg:text-xl md:text-xl xl:text-xl sm:text-base xs:text-sm font-bold leading-6 tracking-[.2em]">
                  {model.data.email}
                </p>
              </Link>
            </div>
            {model.data.fotos.map((foto, index) => (
              <figure key={index} className=" mb-5">
                <img className="mx-auto" src={foto} alt={`Fotito ${index}`} />
              </figure>
            ))}
            <div className="mt-7 w-72 h-16 mx-auto mb-9">
              <button
                onClick={() => window.history.back()}
                className="w-full h-full border-black border mx-auto"
              >
                <span className="text-black font-bold text-base leading-5 tracking-[.05em] uppercase">
                  Volver
                </span>
              </button>
            </div>
          </div>
        </>
      )}
    </motion.div>
  );
};
