import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { Error } from "../components/Error";
import { HomePage } from "../pages/home";
import { ModelsPage } from "../pages/models";

import { AnimatePresence } from "framer-motion";
import { Path } from "../pages/models/path";

export const AppRouter = () => {
  const location = useLocation();
  return (
    <>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<HomePage />} />
          <Route path="/modelos" element={<ModelsPage />}/>
          <Route path="/modelos/:path" element={<Path />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </AnimatePresence>
    </>
  );
};
