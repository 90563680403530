import React from "react";

export const Explain = () => {
  return (
    <div className="py-8 container mx-auto">
      <p className=" text-xl leading-8 text-justify tracking-wider">
        Una sociedad es más justa cuando las oportunidades son para todos, sin
        embargo solo el 23% de peruanos con discapacidad visual cuenta con un
        trabajo.
      </p>
      <p className=" text-xl leading-8 text-justify mt-10 tracking-wider">
        Es por eso que nace{" "}
        <span className=" uppercase font-bold">MODELOS QUE SE HACEN VER,</span>{" "}
        una iniciativa del Centro de Rehabilitación para Ciegos de Lima CERCIL,
        que busca captar la atención de las distintas marcas para que contraten
        a personas con ceguera o baja visión como modelos en sus campañas
        publicitarias y así reinsertarlos en el mercado laboral.
      </p>
      <p className=" text-xl leading-8 text-justify mt-10 tracking-wider font-bold">
        Quién mejor que ellos para modelar con lentes de sol si llevan una vida
        usándolos.
      </p>
    </div>
  );
};
