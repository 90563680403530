import React from "react";
import { Link } from "react-router-dom";
import LogoCercil from "../assets/images/logos/logo_about.png";

export const AboutCercil = () => {
  return (
    <div className="flex xl:flex-row lg:flex-row flex-row sm:flex-col xs:flex-col  justify-around items-start mx-auto">
      <div className="xs:mx-auto">
        <img src={LogoCercil} className="xs:mx-auto" alt=" Logo Cercil" />
      </div>
      <div className="xs:mx-auto" style={{ maxWidth: "570px" }}>
        <p className="text-white leading-6 font-normal text-lg text-justify">
          El Centro de Rehabilitación de Ciegos de Lima, CERCIL, es una
          institución privada sin fines de lucro, especializada en la atención
          integral de las personas con discapacidad visual mayores de 14 años.
          Cuenta con un equipo de profesionales multidisciplinario y de amplia
          experiencia, cuya labor llega hasta la inclusión útil y productiva de
          las personas con discapacidad visual en la comunidad.
        </p>
        {/* https://cercil.org/ */}
        <Link
          to="#"
          onClick={(e) => {
            window.open(`https://cercil.org/`, "_blank");
            e.preventDefault();
          }}
        >
          <button className="border border-white xl:w-64 lg:w-64 xs:w-full h-14 mt-6">
            <span className="text-white font-bold uppercase text-sm">
              Conoce Más
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
};
