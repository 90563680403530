import React from "react";
// import HoverClick from "../assets/images/icons/icon_hover-click.png";

export const CardModelHome = ({ profile, number }) => {
  return (
    <figure className="relative overflow-hidden">
      <img className="w-full object-cover" src={profile} alt="pic profile" />
      {/* <div
        className="absolute left-0 right-0 mx-auto bottom-8 flex flex-col justify-center align-middle"
        style={{ width: "123px", height: "69px" }}
      >
        <img
          className="mx-auto"
          src={HoverClick}
          alt=""
          width={34}
          height={40}
        />
        <span className="uppercase font-semibold text-white text-center">
          conoce más
        </span>
      </div> */}
    </figure>
  );
};
