import React from "react";
import { Home } from "../../components/Home";

import { motion } from "framer-motion";

export const HomePage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
      <Home />
    </motion.div>
  );
};
