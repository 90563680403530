import React from "react";

import { CardModelHome } from "./CardModelHome";
import { Link } from "react-router-dom";
import { modelsHome } from "../assets/json/data";
import { HeaderModel } from "./HeaderModel";
import { Explain } from "./Explain";
import { AboutCercil } from "./About";

export const Home = () => {
  return (
    <>
      <section className="mx-auto py-5 container px-0 xl:px-0 lg:px-2 sm:px-1 xs:px-1 md:px-3">
        <Explain />
      </section>
      <section className="pt-7 pb-16 container mx-auto  px-0 xl:px-0 lg:px-2 md:px-3 sm:px-1 xs:px-1 ">
        <HeaderModel />
        <div className="mx-auto py-8 container">
          <div className="grid  grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 gap-10">
            {modelsHome.map((item, key) => (
              <CardModelHome {...item} key={key} number={key} />
            ))}
          </div>
        </div>
        <div
          className="w-full m-auto"
          style={{ width: "298px", height: "62px" }}
        >
          <Link to="/modelos">
            <button className="text-center border-2 border-black w-full h-full cursor-pointer">
              <span className="text-black text-lg uppercase font-bold">
                {" "}
                Ver TODOs los modelos
              </span>
            </button>
          </Link>
        </div>
      </section>
      <section className=" py-12 bg-black">
        <div className="container  mx-auto">
          <AboutCercil />
        </div>
      </section>
    </>
  );
};
