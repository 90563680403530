import React from "react";
import "./styles.scss";

import { Link } from "react-router-dom";

export const CardModel = ({ profile, name, lastname, path }) => {
  return (
    <figure className="card">
      <img src={profile} alt="dft img" className="w-full h-auto" />
      <div className="card_hover flex flex-col">
        <div className="pt-16">
          <h3 className="text-white">{name}</h3>
          <h3 className="text-white">{lastname}</h3>
          <div className=" h-1 w-9 bg-white mt-6 mx-auto"></div>
        </div>
        <div>
          <Link to={path}>
            <button className="bg-white py-4 w-full mb-6">
              <span className="text-black text-sm uppercase">Ver más</span>
            </button>
          </Link>
        </div>
      </div>
    </figure>
  );
};
