import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logos/logo.png";
import Nav from "../assets/images/icons/icon_navbarr.svg";
import Facebook from "../assets/images/icons/icon_facebook.svg";
import Youtube from "../assets/images/icons/icon_youtube.svg";
import Instagram from "../assets/images/icons/icon_instagram.svg";
import Close from "../assets/images/icons/icon_close.svg";

export const NavbarToggle = () => {
  const [isShow, setIsShow] = React.useState(false);
  const paths = [
    {
      text: "Inicio",
      path: "/",
    },
    {
      text: "Nuestros modelos",
      path: "/modelos",
    },
  ];

  const handleClick = () => {
    setIsShow(!isShow);
  };

  return (
    <nav className="bg-black flex justify-center py-11">
      <Link to={"/"}>
        <img src={Logo} alt="logo home" className="lg:w-auto sm:w-60 xs:w-60" />
      </Link>
      {isShow ? (
        <div className="absolute z-20 bg-black right-0 top-0 p-10 flex flex-col justify-between xs:w-full sm:w-full lg:w-[512px] md:w-[512px] xl:w-[512px] xl:h-[790px] lg:h-[790px] md:h-[500px] sm:h-full xs:h-full">
          <button
            onClick={handleClick}
            className=" ml-auto float-right"
            style={{ width: 20, height: 20 }}
          >
            <img src={Close} alt="icon close" />
          </button>
          <ul className=" list-none text-white">
            {paths.map((item, key) => (
              <li key={key} className="py-8 border-b-[1px] border-white">
                <button>
                  <Link
                    className="text-xl uppercase font-bold"
                    to={item.path}
                    onClick={handleClick}
                  >
                    {item.text}
                  </Link>
                </button>
              </li>
            ))}
          </ul>

          <div className="mt-28">
            <p className="text-white uppercase text-base mb-8 font-bold">
              Síguenos en:
            </p>
            <ul
              className="list-none flex flex-row justify-between items-center text-white"
              style={{ width: "190px", height: "38px" }}
            >
              <li>
                <button>
                  <Link
                    to={"#"}
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/cercil.surco",
                        "_blank"
                      );
                    }}
                  >
                    <img src={Facebook} alt="logo FB" width={17} height={36} />
                  </Link>
                </button>
              </li>
              <li>
                <button>
                  <Link
                    to={"/"}
                    onClick={() => {
                      window.open(
                        "https://www.instagram.com/cercil_peru/",
                        "_blank"
                      );
                    }}
                  >
                    <img src={Instagram} alt="logo IG" width={38} height={38} />
                  </Link>
                </button>
              </li>
              <li>
                <button>
                  <Link
                    to={"#"}
                    onClick={() => {
                      window.open(
                        "https://www.youtube.com/channel/UCXIlCw_BeTi6LIJ1FbAPIEw",
                        "_blank"
                      );
                    }}
                  >
                    <img src={Youtube} alt="logo YT" width={41} height={30} />
                  </Link>
                </button>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <button
          onClick={handleClick}
          className="absolute top-10 right-20 lg:top-10 lg:right-20 z-10 md:top-5 sm:top-5 xs:top-5 md:right-5 sm:right-5 xs:right-5"
        >
          <img src={Nav} alt="icon navbar" />
        </button>
      )}
    </nav>
  );
};
