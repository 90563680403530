import React from "react";
import Facebook from "../assets/images/icons/icon_facebook.svg";
import Youtube from "../assets/images/icons/icon_youtube.svg";
import Instagram from "../assets/images/icons/icon_instagram.svg";
import Phone from "../assets/images/icons/icon_phone.svg";
import Mail from "../assets/images/icons/icon_mail.svg";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="bg-black">
      <div className="py-8 xl:px-16 lg:px-10 md:px-5 sm:px-5 xs:px-3">
        <div className="flex xl:flex-row lg:flex-col md:flex-col sm:flex-col xs:flex-col justify-between">
          <div className="flex xl:flex-row lg:flex-row md:flex-col sm:flex-col xs:flex-col">
            <div className="flex items-center px-5 py-1">
              <p className="text-white font-normal text-base leading-5">
                Copyright @ 2022 Cercil. Todos los derechos reservados.
              </p>
            </div>
            <div className="flex px-5 items-center xl:border-white lg:border-white lg:border-l-[1px] xl:border-l-[1px] md:border-black sm:border-black xs:border-black py-1 leading-5 xl:mt-0 lg:mt-0 md:mt-3 sm:mt-4 xs:mt-4">
              <img src={Mail} alt="" width={25} height={16} />
              <Link
                to={"#"}
                onClick={(e) => {
                  window.location = "mailto:icercilsurco@hotmail.com";
                  e.preventDefault();
                }}
              >
                <p className="text-white ml-4 font-normal text-base">
                  cercilsurco@hotmail.com{" "}
                </p>
              </Link>
            </div>
            <div className="flex px-5 items-center xl:border-white lg:border-white lg:border-l-[1px] xl:border-l-[1px] md:border-black sm:border-black xs:border-black py-1 leading-5 xl:mt-0 lg:mt-0 md:mt-3 sm:mt-4 xs:mt-4">
              <img src={Phone} alt="" width={20} height={20} />
              <Link
                to="#"
                onClick={(e) => {
                  window.location = "tel:+012754846";
                  e.preventDefault();
                }}
              >
                <p className="text-white ml-4 font-normal font text-base">
                  (01) 2754846
                </p>
              </Link>
            </div>
          </div>
          <ul
            className="list-none flex flex-row justify-between items-center text-white lg:mt-5 md:pl-5 sm:pl-5 xs:pl-5 md:mt-7 sm:mt-7 xs:mt-7"
            style={{ width: "119px", height: "25px" }}
          >
            <li>
              <button>
                <Link
                  to={"#"}
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/cercil.surco",
                      "_blank"
                    );
                  }}
                >
                  <img src={Facebook} alt="logo FB" width={10} height={22} />
                </Link>
              </button>
            </li>
            <li>
              <button>
                <Link
                  to={"/"}
                  onClick={() => {
                    window.open(
                      "https://www.instagram.com/cercil_peru/",
                      "_blank"
                    );
                  }}
                >
                  <img src={Instagram} alt="logo IG" width={23} height={23} />
                </Link>
              </button>
            </li>
            <li>
              <button>
                <Link
                  to={"#"}
                  onClick={() => {
                    window.open(
                      "https://www.youtube.com/channel/UCXIlCw_BeTi6LIJ1FbAPIEw",
                      "_blank"
                    );
                  }}
                >
                  <img src={Youtube} alt="logo YT" width={26} height={18} />
                </Link>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
