import React from "react";
import { CardModel } from "../../components/CardModel";
import { models } from "../../assets/json/data";
import { HeaderModel } from "../../components/HeaderModel";

import { motion } from "framer-motion";

export const ModelsPage = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
      <div className="mx-auto py-12 container xl:px-0 lg:px-2 sm:px-3 xs:px-3 md:px-3">
        <HeaderModel />
        <div className="grid gap-4 grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 mt-10">
          {models.map((item, key) => (
            <CardModel {...item} key={key} />
          ))}
        </div>
      </div>
    </motion.div>
  );
};
