import img_01 from "../images/models/adriana_gonzales.jpg";
import img_01_1 from "../images/models/adriana_gonzales_1.jpg";
import img_01_2 from "../images/models/adriana_gonzales_2.jpg";

import img_02 from "../images/models/corina_upiachichua.jpg";
import img_02_1 from "../images/models/corina_upiachichua_1.jpg";

import img_03 from "../images/models/gabriel_vega.jpg";
import img_03_1 from "../images/models/gabriel_vega_1.jpg";

import img_04 from "../images/models/guiliana_garcia.jpg";
import img_04_1 from "../images/models/guiliana_garcia_1.jpg";

import img_05 from "../images/models/jorge_perez.jpg";
import img_05_1 from "../images/models/jorge_perez_1.jpg";

import img_06 from "../images/models/juan_carlos.jpg";
import img_06_1 from "../images/models/juan_carlos_castro_1.jpg";
import img_06_2 from "../images/models/juan_carlos_castro_2.jpg";
import img_06_3 from "../images/models/juan_carlos_castro_3.jpg";
import img_06_4 from "../images/models/juan_carlos_castro_4.jpg";

import img_07 from "../images/models/grace_paredes.jpg";
import img_07_1 from "../images/models/grace_paredes_1.jpg";

import img_08 from "../images/models/ivanohe_lazaro.jpg";
import img_08_1 from "../images/models/ivanohe_lazaro_1.jpg";
import img_08_2 from "../images/models/ivanohe_lazaro_2.jpg";
import img_08_3 from "../images/models/ivanohe_lazaro_3.jpg";

import img_10 from "../images/models/juan_julca.jpg";
import img_10_1 from "../images/models/juan_julca_1.jpg";

import img_11 from "../images/models/kenny_nima.jpg";
import img_11_1 from "../images/models/kenny_nima_1.jpg";

import img_12 from "../images/models/maria_ormeño.jpg";
import img_12_1 from "../images/models/maria_ormeño_1.jpg";

import img_13 from "../images/models/omar_muñoz.jpg";
import img_13_1 from "../images/models/omar_muñoz_1.jpg";

import img_14 from "../images/models/piero_bedoya.jpg";
import img_14_1 from "../images/models/piero_bedoya_1.jpg";

import img_15 from "../images/models/sergio_caichihua.jpg";
import img_15_1 from "../images/models/sergio_caichihua_1.jpg";
import img_15_2 from "../images/models/sergio_caichihua_2.jpg";

import img_16 from "../images/models/silvia_castro.jpg";
import img_16_1 from "../images/models/silvia_castro_1.jpg";

import img_17 from "../images/models/victor_sanchez.jpg";
import img_17_1 from "../images/models/vitor_sanchez_1.jpg";

import img_18 from "../images/models/gladys_pizarro.jpg";
import img_18_1 from "../images/models/gladys_pizarro_1.jpg";
import img_18_3 from "../images/models/gladys_pizarro_3.jpg";


import img_19 from "../images/models/graciana_ascuña.jpg";
import img_19_1 from "../images/models/graciana_ascuña_1.jpg";
import img_19_2 from "../images/models/graciana_ascuña_2.jpg";

import img_20 from "../images/models/janeth_delgado.jpg";
import img_20_1 from "../images/models/janeth_delgado_1.jpg";
import img_20_2 from "../images/models/janeth_delgado_2.jpg";
import img_20_3 from "../images/models/janeth_delgado_3.jpg";

import img_21 from "../images/models/johana_contreras.jpg";
import img_21_1 from "../images/models/johana_contreras_1.jpg";
import img_21_2 from "../images/models/johana_contreras_2.jpg";

import img_22 from "../images/models/lourdes_aquije.jpg";
import img_22_1 from "../images/models/lourdes_aquije_1.jpg";
import img_22_2 from "../images/models/lourdes_aquije_2.jpg";
import img_22_3 from "../images/models/lourdes_aquije_3.jpg";

import img_23 from "../images/models/lucho_pizarro.jpg";
import img_23_1 from "../images/models/lucho_pizarro_1.jpg";
import img_23_2 from "../images/models/lucho_pizarro_2.jpg";
import img_23_3 from "../images/models/lucho_pizarro_3.jpg";

import img_24 from "../images/models/erling_medina.jpg";
import img_24_1 from "../images/models/erling_medina_1.jpg";
import img_24_2 from "../images/models/erling_medina_2.jpg";

import img_25 from "../images/models/cesar_chinchay.jpg";
import img_25_1 from "../images/models/cesar_chinchay_1.jpg";
import img_25_2 from "../images/models/cesar_chinchay_2.jpg";
import img_25_3 from "../images/models/cesar_chinchay_3.jpg";
import img_25_4 from "../images/models/cesar_chinchay_4.jpg";

export const models = [
  {
    path: "ivanohe_lazaro",
    profile: img_08,
    name: "Ivanohe",
    lastname: "Lázaro",
    grade: "Fisioterapia",
    age: "28 años",
    job: "Masajista",
    free_time: "Paradeportista.",
    data: {
      name: "IVANOHE LÁZARO LA ROSA",
      phone: "949 006 443",
      email: "",
      fotos: [img_08_1, img_08_2, img_08_3],
    },
  },
  {
    path: "juancarlos_castro",
    profile: img_06,
    name: "Juan Carlos",
    lastname: "Castro",
    age: "37 años",
    job: "Publicidad",
    free_time: "Actuación y Stand Up Comedy.",
    data: {
      name: "JUAN CARLOS CASTRO GERKENS",
      phone: "985 444 881",
      email: "juancarloscgerkens@gmail.com",
      fotos: [
       img_06_1, img_06_2, img_06_3, img_06_4
      ],
    },
  },
  {
    path: "lourdes_aquije",
    profile: img_22,
    name: "Lourdes",
    lastname: "Aquije",
    age: "22 años",
    job: "Masajista",
    free_time: "Jugar fútbol y bailar salsa.",
    data: {
      name: "LOURDES AQUIJE SOLANO",
      phone: "960 601 783",
      email: "",
      fotos: [
        img_22_1, img_22_2, img_22_3
      ],
    },
  },
  {
    path: "erling_medina",
    profile: img_24,
    name: "Erling",
    lastname: "Medina",
    age: "22 años",
    job: "Masajista",
    free_time: "Jugar fútbol y bailar salsa.",
    data: {
      name: "ERLING MEDINA",
      phone: "954 381 517",
      email: " ",
      fotos: [
        img_24_2, img_24_1
      ],
    },
  },
  {
    path: "graciana_ascuna",
    profile: img_19,
    name: "Graciana",
    lastname: "Ascuña",
    age: "22 años",
    job: "Masajista",
    free_time: "Jugar fútbol y bailar salsa.",
    data: {
      name: "GRACIANA ASCUÑA",
      phone: "936 551 079",
      email: " ",
      fotos: [
        img_19_2, img_19_1
      ],
    },
  },
  {
    path: "adriana_gonzales",
    profile: img_01,
    name: "Adriana",
    lastname: " Gonzales",
    age: "19 años",
    job: "Estudios Pre-Universitarios",
    free_time: "Cantar, leer y dibujar.",
    data: {
      name: "ADRIANA GONZALES ARONI",
      phone: "981 511 281",
      email: "adrigaroni@gmail.com",
      fotos: [
        img_01_2, img_01_1
      ],
    },
  },
  {
    path: "grace_paredes",
    profile: img_07,
    name: "Grace",
    lastname: "Paredes",
    age: "24 años",
    job: "Terapia Física",
    free_time: "Cocinar postres.",
    data: {
      name: "GRACE PAREDES ENCARNACIÓN",
      phone: "922 770 200",
      email: " ",
      fotos: [
        img_07_1,
      ],
    },
  },
  {
    path: "gabriel_vega",
    profile: img_03,
    name: "Gabriel",
    lastname: "Vega",
    age: "17 años",
    job: "Estudios",
    free_time: "Hacer ejercicios y cocinar.",
    data: {
      name: "GABRIEL VEGA PUICÓN",
      phone: "979 977 013",
      email: "gabrielXPRIM2019@gmail.com",
      fotos: [
        img_03_1,
      ],
    },
  },
  {
    path: "lucho_pizarro",
    profile: img_23,
    name: "Lucho",
    lastname: "Pizarro",
    age: "22 años",
    job: "Masajista",
    free_time: "Jugar fútbol y bailar salsa.",
    data: {
      name: "LUCHO PIZARRO",
      phone: "994 652 232",
      email: "alberto2012p@gmail.com",
      fotos: [
        img_23_3, img_23_1, img_23_2
      ],
    },
  },
  {
    path: "jorgeluis_perez",
    profile: img_05,
    name: "Jorge Luis",
    lastname: "Pérez",
    grade: "Fisioterapia",
    age: "45 años",
    job: "Fisioterapia y Paradeportista",
    free_time: "Leer libros y pasear.",
    data: {
      name: "JORGE PÉREZ ROMERO",
      phone: "994 427 046",
      email: " ",
      fotos: [
        img_05_1
      ],
    },
  },
  {
    path: "johana_contreras",
    profile: img_21,
    name: "Johana",
    lastname: "Contreras",
    age: "22 años",
    job: "Masajista",
    free_time: "Jugar fútbol y bailar salsa.",
    data: {
      name: "JOHANNA CONTRERAS",
      phone: "946 021 988",
      email: " ",
      fotos: [
        img_21_2, img_21_1
      ],
    },
  },
  {
    path: "juan_julca",
    profile: img_10,
    name: "Juan",
    lastname: "Julca",
    grade: "Diseño Gráfico",
    age: "29 años",
    job: "En tienda por departamento",
    free_time: "Practicar deportes.",
    data: {
      name: "JUAN JULCA PÉREZ",
      phone: "991 810 488",
      email: " ",
      fotos: [
        img_10_1
      ],
    },
  },
  {
    path: "cesar_chinchay",
    profile: img_25,
    name: "César",
    lastname: "Chinchay",
    grade: "Diseño Gráfico",
    age: "29 años",
    job: "En tienda por departamento",
    free_time: "Practicar deportes.",
    data: {
      name: "CÉSAR ANTONIO CHINCHAY ZERPA",
      phone: "976 320 301",
      email: " ",
      fotos: [
        img_25_4, img_25_3, img_25_1,img_25_2,
      ],
    },
  },
  {
    path: "piero_bedoya",
    profile: img_14,
    name: "Piero",
    lastname: "Bedoya",
    grade: "Marketing e Historia",
    age: "49 años",
    job: "Masoterapia",
    free_time: "Escuchar música.",
    data: {
      name: "PIERO BEDOYA DOMINGUEZ",
      phone: "993 731 083",
      email: " ",
      fotos: [
        img_14_1
      ],
    },
  },
  {
    path: "silvia_castro",
    profile: img_16,
    name: "Silvia",
    lastname: "Castro",
    age: "33 años",
    job: "Masoterapeuta",
    free_time: "La danza y escuchar música.",
    data: {
      name: "SILVIA CASTRO VALDEZ",
      phone: "932 363 895",
      email: " ",
      fotos: [
        img_16_1
      ],
    },
  },
  {
    path: "gladys_pizarro",
    profile: img_18,
    name: "Gladys",
    lastname: "Pizarro",
    age: "33 años",
    job: "Masoterapeuta",
    free_time: "La danza y escuchar música.",
    data: {
      name: "GLADYS CECILIA PIZARRO ECHAIZ",
      phone: "963 814 695",
      email: "gladyspe2402@hotmail.com",
      fotos: [
        img_18_3, img_18_1
      ],
    },
  },
  {
    path: "victor_sanchez",
    profile: img_17,
    name: "Víctor",
    lastname: "Sánchez",
    age: "22 años",
    job: "Masajista",
    free_time: "Jugar fútbol y bailar salsa.",
    data: {
      name: "VÍCTOR SÁNCHEZ GONZALES",
      phone: "986 033 464",
      email: " ",
      fotos: [
        img_17_1
      ],
    },
  },
  {
    path: "giuliana_garcia",
    profile: img_04,
    name: "Giuliana",
    lastname: "Garcia",
    age: "49 años",
    job: "Terapeuta y mamá",
    free_time: "Recordar la naturaleza y escuchar música.",
    data: {
      name: "GIULIANA GARCÍA RAMÍREZ",
      phone: "994 465 081",
      email: " ",
      fotos: [
        img_04_1
      ],
    },
  },
  {
    path: "janeth_delgado",
    profile: img_20,
    name: "Janeth",
    lastname: "Delgado",
    age: "22 años",
    job: "Masajista",
    free_time: "Jugar fútbol y bailar salsa.",
    data: {
      name: "JANETH DELGADO",
      phone: "947 131 021",
      email: "janethmagaly21@gmail.com",
      fotos: [
        img_20_1, img_20_2, img_20_3
      ],
    },
  },
  {
    path: "mariaelena_ormeno",
    profile: img_12,
    name: "María Elena",
    lastname: "Ormeño",
    age: "54 años",
    job: "Masajista",
    free_time: "Tejer y disfrutar de la naturaleza.",
    data: {
      name: "MARÍA ELENA ORMEÑO YATOPA",
      phone: "917 125 813",
      email: " ",
      fotos: [
        img_12_1
      ],
    },
  },
  {
    path: "omar_munoz",
    profile: img_13,
    name: "Omar",
    lastname: "Muñoz",
    age: "26 años",
    job: "Ayuda en casa",
    free_time: "Escuchar música y tocar batería.",
    data: {
      name: "OMAR MUÑOZ BALABARCA",
      phone: "986 301 563",
      email: " ",
      fotos: [
        img_13_1
      ],
    },
  },
  {
    path: "corina_upiachihua",
    profile: img_02,
    name: "Corina",
    lastname: "Upiachihua",
    age: "36 años",
    job: "Masoterapeuta",
    free_time: "Escuchar música.",
    data: {
      name: "CORINA UPIACHIHUA ZUMAETA",
      phone: "918 522 206",
      email: " ",
      fotos: [
        img_02_1
      ],
    },
  },
  {
    path: "kenny_cienfuegos",
    profile: img_11,
    name: "Kenny Nima",
    lastname: "Cienfuegos",
    age: "48 años",
    job: "Terapista",
    free_time: "Escuchar música y bailar salsa.",
    data: {
      name: "KENNY NIMA CIENFUEGOS",
      phone: "963 895 591",
      email: " ",
      fotos: [
        img_11_1
      ],
    },
  },
  {
    path: "sergio_caichihua",
    profile: img_15,
    name: "Sergio",
    lastname: "Caichihua",
    age: "36 años",
    job: "Terapista",
    free_time: "Gestión y emprendimiento.",
    data: {
      name: "SERGIO CAICHIHUA CONTRERAS",
      phone: "989 685 572",
      email: " ",
      fotos: [
        img_15_2, img_15_1
      ],
    },
  },
];

export const modelsHome = [
  {
    profile: img_20,
    name: "Janeth",
    lastname: "Delgado",
    age: "22 años",
    job: "Masajista",
    free_time: "Jugar fútbol y bailar salsa.",
  },
  {
    profile: img_06,
    name: "Juan Carlos",
    lastname: "Castro",
    age: "37 años",
    job: "Publicidad",
    free_time: "Actuación y Stand Up Comedy.",
  },
  {
    profile: img_22,
    name: "Lourdes",
    lastname: "Aquije",
    age: "22 años",
    job: "Masajista",
    free_time: "Jugar fútbol y bailar salsa.",
  },
  {
    profile: img_24,
    name: "Erling",
    lastname: "Medina",
    age: "22 años",
    job: "Masajista",
    free_time: "Jugar fútbol y bailar salsa.",
  },
  {
    profile: img_19,
    name: "Graciana",
    lastname: "Ascuña",
    age: "22 años",
    job: "Masajista",
    free_time: "Jugar fútbol y bailar salsa.",
  },
  {
    profile: img_25,
    name: "César",
    lastname: "Chinchay",
    grade: "Diseño Gráfico",
    age: "29 años",
    job: "En tienda por departamento",
    free_time: "Practicar deportes.",
  },
];
