import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './routes/AppRouter';
import { Footer } from './components/Footer';
import { NavbarToggle } from './components/NavbarToggle';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <NavbarToggle />
      <AppRouter/>
      <Footer/>
    </BrowserRouter>
  </React.StrictMode>
);
