import React from "react";

export const HeaderModel = ({ text = "Nuestros modelos" }) => {
  return (
    <div className=" relative overflow-hidden h-8">
      <p
        style={{ width: "fit-content" }}
        className="font-bold text-center mx-auto bg-white xl:text-3xl lg:text-3xl md:text-3xl sm:text-xl xs:text-lg uppercase z-100 absolute inset-0 z-0 xl:px-12 lg:px-12 md:px-12 sm:px-3 xs:px-4 tracking-wider leading-7"
      >
        {text}
      </p>
      <div
        className=" bg-black mx-auto xl:mt-4 lg:mt-4 md:mt-4 sm:mt-3 xs:mt-3"
        style={{ width: "100%", height: "2px" }}
      ></div>
    </div>
  );
};
